import React, { useState, useEffect, useRef } from 'react'
import { Box, CircularProgress, Grid } from '@mui/material'
import moment from 'moment';

const ThanksText = () => {
  // const now = new Date().getTime()
  // const deadline = moment(now).add(10, 's')
  // const [seconds, setSeconds] = useState(0)

  // const getTime = () => {
  //   if (seconds < 0) {
  //     const time = Date.parse(deadline) - Date.now();
  //     setSeconds(Math.floor((time / 1000) % 60));
  //     return 10
  //   }
  // };

  // useEffect(() => {
  //   const interval = setInterval(() => getTime(deadline), 1000);

  //   return () => clearInterval(interval);
  // }, []);

  return (
    <Grid item xs={12} align='center'>
      <Box mb={1}>
        <span className='thanks-opsi-saran'>
          Terima Kasih Atas Kritik dan Sarannya
        </span>
      </Box>
      {/* <br /> */}
      <span className='title-opsi-saran'>
        Kami Akan Terus Meningkatkan Pelayanan yang Kami Berikan
      </span>
      <br />
      <br />
      <span className='pesan-otomatis'>Pesan Ini Akan Otomatis Tertutup Dalam 6 Detik..</span>
      {/* <br />
      <span>{seconds}</span> */}
    </Grid>
  )
}

export default ThanksText
