import React from 'react'
import { Backdrop, CircularProgress } from '@mui/material'
import { useRecoilValue } from 'recoil'
import { backdropState } from '../recoil/atoms'

const MyBackdrop = () => {
  const showBackdrop = useRecoilValue(backdropState)
  return (
    <>
      {/* {backdropType === 'modal' ? (
        <Backdrop
          sx={{ color: '#777', zIndex: (theme) => theme.zIndex.modal + 1 }}
          open={showBackdrop}
        >
          <CircularProgress color={backdropColor} />
        </Backdrop>
      ) : ( */}
      <Backdrop
        sx={{ color: '#777', zIndex: (theme) => theme.zIndex.modal + 1 }}
        open={showBackdrop}
      >
        <CircularProgress color='primary' />
      </Backdrop>
      {/* )} */}
    </>
  )
}

export default MyBackdrop
