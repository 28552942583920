import { CircularProgress } from '@mui/material'
import React, { lazy, Suspense, useEffect, useRef } from 'react'
import './App.css';
import { MyBackdrop } from './components'
import ReactFullscreen from 'react-easyfullscreen'

const Landing = lazy(() => import('./pages/landing/Landing'))

const App = () => {
  const btnRef = useRef()

  return (
    <ReactFullscreen>
      {({ ref, onRequest, onExit }) => (
        <div className="app">
          <Suspense fallback={<CircularProgress color='primary' />}>
            <Landing fullscreen={onRequest} exitFullscreen={onExit} />
          </Suspense>
          <MyBackdrop />
        </div>
      )}
    </ReactFullscreen>
  );
}

export default App;
